<template>
  <v-container fluid>
    <Header headerClass="politiche" title="Politiche per l'aria" />
    <v-container page id="contenuto">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Progetti</h2>

          <v-card flat class="mt-40">
            <v-list-item three-line>
              <v-list-item-content>
                <h3>PREPAIR</h3>
                <p>
                  Il progetto LIFE Integrato
                  <a
                    href="https://www.lifeprepair.eu"
                    target="_blank"
                    title="Questo link si apre in una nuova scheda"
                    alt="Questo link si apre in una nuova scheda"
                    >PREPAIR</a
                  >, finanziato dalla UE, mira a implementare le misure previste
                  dai Piani regionali e dall’
                  <a
                    href="http://www.regione.piemonte.it/pinforma/images/DOCUMENTI/accordo-bacino-padano.pdf"
                    target="_blank"
                    title="Questo link si apre in una nuova scheda"
                    alt="Questo link si apre in una nuova scheda"
                    >Accordo di Bacino Padano</a
                  >
                  , e a rafforzarne la sostenibilità e la durabilità dei
                  risultati: il progetto copre la
                  <strong>valle del Po</strong> e le regioni e le città che
                  influenzano maggiormente la qualità dell’aria nel bacino.<br />
                  Le azioni di progetto si estendono alla Slovenia, con lo scopo
                  di valutare e ridurre il trasporto di inquinanti anche oltre
                  il mare Adriatico.
                </p>
                <p>Presentazione video del progetto:</p>
                <div class="lista">
                  <ul>
                    <li>
                      <a
                        href="https://youtu.be/N4qU61npgK8"
                        target="_blank"
                        title="Questo link si apre in una nuova scheda"
                        alt="Questo link si apre in una nuova scheda"
                        >italiano
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtu.be/4AZORvKCH3o"
                        target="_blank"
                        title="Questo link si apre in una nuova scheda"
                        alt="Questo link si apre in una nuova scheda"
                        >english
                      </a>
                    </li>
                  </ul>
                </div>
                <p class="text-center mt-60">
                  <img
                    alt="PREPAIR"
                    src="../../assets/stylesheets/im/logo-prepair.png"
                  />
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuPolitiche page="progetti-regione" />
        </v-col>
      </v-row>
    </v-container>
    <div class="progetti">
      <v-container>
        <v-row justify-center>
          <v-col cols="8" offset="2">
            <p>
              Il progetto è guidato dalla Regione Emilia Romagna, e coinvolge 17
              partner tra cui Regione Piemonte.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container hexagon>
      <v-row>
        <v-col class="col-md-9 col-12">
          <v-card flat>
            <v-list-item three-line class="center">
              <v-list-item-content>
                <h3>Obiettivi</h3>
                <p>
                  Supportare la piena implementazione dei Piani di qualità
                  dell’aria (AQPs) e delle misure dell’Accordo di Bacino Padano
                  su una scala territoriale più ampia
                </p>
                <p class="mt-40">
                  Stabilire una infrastruttura permanente di condivisione dei
                  dati per il monitoraggio e la valutazione della qualità
                  dell’aria e l’implementazione delle misure nell’area di
                  progetto.
                </p>
                <p class="mt-40">
                  Stabilire una infrastruttura permanente di condivisione dei
                  dati per il monitoraggio e la valutazione della qualità
                  dell’aria e l’implementazione delle misure
                </p>
                <p class="mt-40">
                  Stabilire una piattaforma permanente di governance composta da
                  amministrazioni che gestiscono la qualità dell’aria, agenzie
                  ambientali, autorità di gestione dei fondi complementari e
                  stakeholder
                </p>
                <p class="mt-40">
                  Creare una comunità che riconosca i rischi per la salute umana
                  e per l'ambiente causati dall'inquinamento atmosferico
                </p>
                <p class="mt-40">
                  Istituire un network permanente di governi nazionali,
                  regionali e locali, attori socioeconomici, centri di ricerca e
                  altre parti interessate.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuPolitiche from "@/views/politiche/MenuPolitiche";

export default {
  components: {
    Header,
    MenuPolitiche
  }
};
</script>
